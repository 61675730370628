import React from 'react';
import {DrawerMenu, DrawerMenuItem} from '@onsmart/ui-kit';

import {RouteEnum} from 'components/App';
import {Role} from 'models';

import type {FC} from 'react';
interface Props {
  roles?: Role[];
  activeNavLink: string;
  onMenuClick: () => void;
}
const Item: FC<Pick<Props, 'activeNavLink' | 'onMenuClick'> & {route: RouteEnum}> = ({
  children,
  route,
  activeNavLink,
  onMenuClick,
}) => (
  <DrawerMenuItem
    href={`#${route}`}
    active={activeNavLink.startsWith(`#${route}`)}
    onClick={onMenuClick}
  >
    {children}
  </DrawerMenuItem>
);
export const AppDrawerContent: FC<Props> = ({activeNavLink, onMenuClick, roles}: Props) => {
  const isAdmin = (roles ?? []).includes(Role.admin);

  return (
    <DrawerMenu>
      <Item activeNavLink={activeNavLink} onMenuClick={onMenuClick} route={RouteEnum.markets}>
        Markets
      </Item>
      <Item activeNavLink={activeNavLink} onMenuClick={onMenuClick} route={RouteEnum.panels}>
        Surfaces
      </Item>
      <Item activeNavLink={activeNavLink} onMenuClick={onMenuClick} route={RouteEnum.showings}>
        Showings
      </Item>
      <Item activeNavLink={activeNavLink} onMenuClick={onMenuClick} route={RouteEnum.inventory}>
        Inventory
      </Item>
      {isAdmin && (
        <>
          <Item activeNavLink={activeNavLink} onMenuClick={onMenuClick} route={RouteEnum.keywords}>
            Keywords
          </Item>
          <Item activeNavLink={activeNavLink} onMenuClick={onMenuClick} route={RouteEnum.activity}>
            Activity Feed
          </Item>
        </>
      )}
    </DrawerMenu>
  );
};
