import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import {UserMenu} from '@onsmart/ui-kit';

import {environment} from 'config/environment';
import {truncate} from 'utils/string';
import {getAccountLink} from 'utils/url';

import Help from '../Help';
import {styles} from './MainNavRight.style';

import type {MouseEvent} from 'react';
import type {RootState} from 'config/redux/rootReducer';
import type {Styles} from './MainNavRight.style';

type User = RootState['authorization']['user'];

interface Props extends Styles {
  loading: boolean;
  user: User;
  onClickLogoutButton: (e: MouseEvent<HTMLAnchorElement>) => void;
  onAccountClick: () => void;
}

const linkToAccount = getAccountLink();

export const MainNavRight = withStyles(styles)(
  class UserMenuComp extends React.Component<Props> {
    private button: React.RefObject<HTMLDivElement>;

    constructor(props: Props) {
      super(props);
      this.button = React.createRef<HTMLDivElement>();
    }

    renderHelp = () => {
      return <Help />;
    };

    render() {
      return (
        <div style={{display: 'contents'}}>
          {this.renderBetaText()}
          <div ref={this.button}>{this.renderHelp()}</div>
          {this.renderUserMenuContent()}
        </div>
      );
    }

    renderBetaText = () => {
      const {classes} = this.props;
      return (
        <Typography component="span" className={classes.betaText}>
          Beta
        </Typography>
      );
    };
    renderUserMenuContent = () => {
      const {loading, user, onClickLogoutButton, onAccountClick} = this.props;

      return (
        <UserMenu
          loading={loading}
          userName={user?.name || ''}
          imageUrl={user?.picture || ''}
          linkToAccount={linkToAccount}
          userEmail={truncate(user?.email || '', 28)}
          onAccountClick={onAccountClick}
          onLogoutClick={onClickLogoutButton}
          version={environment.settings.app.version}
          build={environment.settings.app.commitHash}
        />
      );
    };
  },
);
