import type {History} from 'history';
import {all} from 'redux-saga/effects';

import {actions} from '../actions';
import {querySync, querySyncFilter, stringTransforms} from './lib';

export default function* init(history: History<unknown>) {
  yield all([
    querySync('$limit', {
      history,
      selector: ({showings: {pagination}}) => pagination.limit,
      action: actions.setPageLimit,
      stringToValue: parseInt,
    }),
    querySync('$skip', {
      history,
      selector: ({showings: {pagination}}) => pagination.skip,
      action: actions.setPageSkip,
      stringToValue: parseInt,
      listeners: [actions.setFilters, actions.resetFilters],
    }),
    querySync('$sort', {
      history,
      selector: ({showings: {pagination}}) => pagination.sort,
      action: actions.setPageSort,
    }),
    querySyncFilter('searchTerm', {
      history,
      selector: ({showings: {filters}}) => filters,
      action: actions.concatFilters,
      stringToValue: (s) => ({
        searchTerm: stringTransforms.stringToValue(s),
      }),
      valueToString: (v) => stringTransforms.valueToString(v.searchTerm),
      listeners: [actions.setFilters, actions.resetFilters],
      defaultValue: {},
    }),
  ]);
}
