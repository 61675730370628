import * as React from 'react';
import IconButton from '@material-ui/core/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';
import Help from '@material-ui/icons/Help';
import {ArrowTooltip} from '@onsmart/ui-kit';

import {styles} from './style';

import type {WithStyles} from '@material-ui/core/styles/withStyles';
import type {TooltipProps} from '@material-ui/core/Tooltip';
interface Props extends WithStyles<typeof styles> {
  tooltipPlacement?: TooltipProps['placement'];
  hideTooltip?: boolean;
}

export default withStyles(styles)(({classes, hideTooltip, tooltipPlacement = 'bottom'}: Props) => (
  <ArrowTooltip
    title="Help"
    placement={tooltipPlacement}
    disableFocusListener={hideTooltip}
    disableHoverListener={hideTooltip}
    disableTouchListener={hideTooltip}
    {...({} as any)}
  >
    <IconButton
      href="https://intercom.help/smartscout/en"
      target="_blank noopener"
      classes={{root: classes.root}}
      color="primary"
      aria-label="Help"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <Help color="primary" />
    </IconButton>
  </ArrowTooltip>
));
