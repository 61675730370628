import equal from 'fast-deep-equal';

export const deepCopy = <T>(obj: T): T => {
  return JSON.parse(JSON.stringify(obj)) as T;
};

export const isEmpty = (obj: object | undefined) => {
  if (obj) {
    for (const key of Object.keys(obj)) {
      return !obj.hasOwnProperty(key);
    }
  }

  return true;
};

const processValue = (curr, value) => {
  if (Array.isArray(value)) {
    return {[curr]: value.sort()};
  } else if (value !== null && typeof value === 'object') {
    return {[curr]: sortArrayProps(value)};
  }

  return {[curr]: value};
};

const sortArrayProps = (objA: any) => {
  return Object.keys(objA).reduce((acc, curr) => {
    const value = objA[curr];
    return {...acc, ...processValue(curr, value)};
  }, {});
};

export const isEqual = (objA: any, objB: any) => {
  if (Array.isArray(objA) && Array.isArray(objB)) {
    return equal(objA.sort(), objB.sort());
  } else if (!isEmpty(objA) && !isEmpty(objB)) {
    return equal(sortArrayProps(objA), sortArrayProps(objB));
  } else {
    return equal(objA, objB);
  }
};
